import {
  HeroSection,
  HeroSectionProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';

import { HeroImageSectionFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface HeroVideoSectionProps {
  hero?: HeroImageSectionFragment | null;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
}

export default function HeroImageSection({
  hero,
  titleMaxWidth,
  subtitleMaxWidth,
}: HeroVideoSectionProps) {
  const heroProps: HeroSectionProps = {
    useCase: 'energy' as useCaseType,
    enabledLazyLoading: true,
    titleMaxWidth,
    subtitleMaxWidth,
    title: hero?.title || '',
    subtitle: hero?.subtitle || '',
    button: hero?.button
      ? {
          useCase: 'energy' as useCaseType,
          label: hero?.button?.label || '',
          path: hero?.button?.path || '',
          openInNewTab: !!hero?.button?.openInNewTab,
        }
      : undefined,
    heroImage: {
      image: {
        imgSrc: getCdlPublicId(
          hero?.heroImage?.img_desktop?.data?.attributes?.url || '',
        ),
        imgSrcMobile: getCdlPublicId(
          hero?.heroImage?.img_mobile?.data?.attributes?.url || '',
        ),
        alt: hero?.heroImage?.alt || '',
        blurHash:
          hero?.heroImage?.img_desktop?.data?.attributes?.blurhash ||
          '12312312',
      },
    },
    heroVideoPreview: hero?.previewVideo?.video?.data?.attributes?.url
      ? {
          enabledLazyLoading: true,
          video: {
            alt: hero?.previewVideo?.poster_alt || '',
            imgPosterSrc:
              getCdlPublicId(
                hero?.previewVideo?.poster?.data?.attributes?.url,
              ) || 'rimac/ui-lib/sample',
            imgPosterSrcMobile:
              getCdlPublicId(
                hero?.previewVideo?.mobilePoster?.data?.attributes?.url,
              ) || 'rimac/ui-lib/sample',
            fullVideo: {
              enabledLazyLoading: true,
              posterAlt: hero?.previewVideo?.poster_alt || '',
              posterSrc:
                getCdlPublicId(
                  hero?.previewVideo?.poster?.data?.attributes?.url,
                ) || 'rimac/ui-lib/sample',
              videoSrc:
                getCdlPublicId(
                  hero?.previewVideo?.video?.data?.attributes?.url,
                ) || ' ',
              autoplay: hero?.previewVideo?.autoplay || true,
              blurHash:
                hero?.previewVideo?.poster?.data?.attributes?.blurhash ||
                'L35rfH^$xZS%Q*t:tni^M{Zyxwxv',
              controls: !!hero?.previewVideo?.controls,
              fullscreen: hero?.previewVideo?.fullscreen || true,
              loop: hero?.previewVideo?.loop || true,
              muted: !!hero?.previewVideo?.muted,
              pauseVideo: !!hero?.previewVideo?.pauseVideo,
            },
            blurHash:
              hero?.previewVideo?.poster?.data?.attributes?.blurhash ||
              'L35rfH^$xZS%Q*t:tni^M{Zyxwxv',
            videoDuration: hero?.previewVideoLength || '',
          },
          onClick: () => {},
        }
      : undefined,
  };

  return <HeroSection {...heroProps} />;
}
