import {
  Footer as FooterComponent,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';

import { FooterQuery } from '@/api';

export const Footer = ({ data }: { data: FooterQuery }) => {
  const footerData = data?.footer?.data?.attributes;

  const columns = (footerData?.column || []).map((column) => ({
    label: column?.lable || '',
    links: (column?.link || []).map((link) => ({
      label: link?.label || '',
      path: link?.file?.data?.attributes?.url || link?.path || '',
      openInNewTab:
        !!link?.file?.data?.attributes?.url || link?.openInNewTab || undefined,
      download: !!link?.file?.data?.attributes?.url,
    })),
  }));

  const socials = {
    label: footerData?.socials?.label || '',
    instagram: footerData?.socials?.instagram || undefined,
    linkedin: footerData?.socials?.linkenin || undefined,
    tiktok: footerData?.socials?.tiktok || undefined,
    youtube: footerData?.socials?.youtube || undefined,
  };

  const policies = {
    label: footerData?.policies?.label || '',
    links: (footerData?.policies?.link || []).map((link) => ({
      path: link?.path || '',
      label: link?.label || '',
      useCase: 'energy' as useCaseType,
    })),
  };

  const contact = {
    title: footerData?.contact?.title || '',
    address: {
      label: footerData?.contact?.address?.label || '',
      path: footerData?.contact?.address?.path || '',
    },
    eMail: {
      label: footerData?.contact?.email?.label || '',
      path: footerData?.contact?.email?.path || '',
    },
  };

  return (
    <FooterComponent
      columns={columns}
      socials={socials}
      policies={policies}
      useCase={'energy' as useCaseType}
      contact={contact}
    />
  );
};
